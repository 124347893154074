<!-- Header Start -->
<app-header-four></app-header-four>
<!-- Header Start -->
<div class="header-1"></div>

<!-- Subheader Start -->
<div *ngIf="brokerage">
  <div
    class="subheader bg-cover bg-center dark-overlay"
    [style.backgroundImage]="'url('+ backgroundImage +')'">
    <div class="container">
      <div class="subheader-inner">
        <h1 class="text-white">{{ this.brokerageName(brokerage) }}</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
            <li class="breadcrumb-item"><a routerLink='/team'>Team</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{(brokerage.legalEntityName) ? brokerage.legalEntityName : 'MVR - ' +brokerage.state}}</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>

<!-- Subheader End -->
<div class="section">
  <div class="container">
    <div class="row">

      <div class="col-lg-4" *ngIf="brokerage">
        <div class="sidebar sidebar-left">
          <div class="sidebar-widget sidebar-widget-agent">
            <div class="media sidebar-author listing-agent">
              <div style="padding-top: 40px">
                <i class="flaticon-company fa-6x"></i>
              </div>
              <div class="media-body">
                <h6>Licensed {{brokerage.state}} Real Estate Professionals</h6>
                <span>Proudly serving your area!</span>
              </div>
            </div>

            <!-- Contact Start -->
            <form [formGroup]="brokerageForm" *ngIf="!submissionBrokerageForm">
              <div class="form-group">
                <span *ngIf="(!brokerageForm.controls['name'].valid)" class="text-warning">Required</span>
                <input type="text" class="form-control" placeholder="Name" name="name" formControlName="name">
              </div>
              <div class="form-group">
                <span *ngIf="(!brokerageForm.controls['email'].valid)" class="text-warning">Required</span>
                <input type="text" class="form-control" placeholder="Email Address" name="email"
                       formControlName="email">
              </div>
              <div class="form-group">
                <span *ngIf="(!brokerageForm.controls['phone'].valid)" class="text-warning">Required</span>
                <input type="text" class="form-control" placeholder="Phone Number" name="phone"
                       formControlName="phone">
              </div>
              <div class="form-group">
                <span *ngIf="(!brokerageForm.controls['address'].valid)" class="text-warning">Required</span>
                <input type="text" class="form-control" placeholder="Address" name="address" value=""
                       formControlName="address">
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="City" name="city" value="" formControlName="city">
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-6">
                    <input type="text" class="form-control" placeholder="State" name="state" value=""
                           formControlName="state">
                  </div>
                  <div class="col-6">
                    <input type="text" class="form-control" placeholder="Zip" name="zip" value="" formControlName="zip">
                  </div>
                </div>
              </div>

              <div class="form-group">
                <span *ngIf="(!brokerageForm.controls['message'].valid)" class="text-warning">Required</span>
                <textarea name="message" rows="3" placeholder="Enter your message" class="form-control"
                          formControlName="message"></textarea>
              </div>

              <div class="form-group">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="terms" formControlName="terms">
                  <label class="form-check-label" for="terms">
                    Submitting this form is consent to the Privacy Policy & Terms of Use and authorizes MV Realty PBC,LLC and affiliates to call, email, SMS via autodialed/prerecorded means
                  </label>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <re-captcha id="recaptcha" name="recaptcha" formControlName="recaptcha" (resolved)="recaptchaResolved($event)" (errored)="recaptchaErrored()"></re-captcha>
                </div>
              </div>

              <button type="submit" class="btn-custom primary btn-block btn-lg" name="button"
                      [disabled]="!brokerageForm.valid"
                      (click)="onSubmitBrokerage()"
              >Contact Brokerage
              </button>
              <div class="text-center" style="padding-top: 20px; font-weight: bold">
                <a href="tel:{{brokerage.phoneNumberE164}}"> <i class="fas fa-phone"></i> Call
                  Now {{brokerage?.phoneNumberE164 | localPhoneNumber}}</a>
              </div>
            </form>
            <!-- Contact End -->
            <h3 *ngIf="submissionBrokerageForm">An Agent will contact you soon</h3>
          </div>
        </div>
      </div>


      <div class="col-lg-8">
        <div class="row">
          <!-- Agent Start -->
          <div class="col-lg-6" *ngFor="let agent of agents">
            <div class="acr-agent">
              <div class="listing-badge featured">
                <i class="fas fa-star"></i>
              </div>

              <div class="acr-dots-wrapper acr-agent-thumb">
                <div class="acr-dots"></div>
                <a><img src="{{agent.profileImageLargeUrl}}" alt="{{agent.fullName}}"></a>
              </div>

              <div class="listing-body">
                <div class="listing-author">
                  <div class="listing-author-body">
                    <h4>{{agent.fullName}}</h4>
                    <span *ngIf="agent.brokerageId === 36">DRE#02178739<br /></span>
                    <span class="listing-date">{{agent.isBroker === 1 ? 'Broker - ' : ''}}Realtor ®</span>
                  </div>
                </div>
                <p class="agent-bio">{{agent.agentBio}}</p>
                <button class="btn btn-outline-primary btn-sm" (click)="openContactAgentModal(content, agent)"><i
                  class="fas fa-envelope"></i> Send Message
                </button>
              </div>
            </div>
          </div>
          <!-- Agent End -->
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #content let-modal [ngIf]="selectedAgent">
  <div class="modal-header">
    <h4 class="modal-title">Contact {{selectedAgent.fullName}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- Contact Start -->
    <form [formGroup]="modalForm">
      <div class="form-group">
        <div class="row">
          <div class="col-12">
            <input type="text" class="form-control" placeholder="Name" name="name" formControlName="name">
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <input type="text" class="form-control" placeholder="Email Address" name="email"
                   formControlName="email">
          </div>
          <div class="col-6">
            <input type="text" class="form-control" placeholder="Phone Number" name="phone"
                   formControlName="phone">
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <input type="text" class="form-control" placeholder="Address" name="address"
                   formControlName="address">
          </div>
          <div class="col-6">
            <input type="text" class="form-control" placeholder="City" name="city" formControlName="city">
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <input type="text" class="form-control" placeholder="State" name="state" formControlName="state">
          </div>
          <div class="col-6">
            <input type="text" class="form-control" placeholder="Zip" name="zip" formControlName="zip">
          </div>
        </div>
      </div>

      <div class="form-group">
        <textarea name="message" rows="3" placeholder="Enter your message" class="form-control"
                  formControlName="message"></textarea>
      </div>

      <div class="form-group">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="terms2" formControlName="terms">
          <label class="form-check-label" for="terms2">
            Submitting this form is consent to the Privacy Policy & Terms of Use and authorizes MV Realty PBC,LLC and affiliates to call, email, SMS via autodialed/prerecorded means
          </label>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <re-captcha id="recaptcha" name="recaptcha" formControlName="recaptcha" (resolved)="recaptchaResolved($event)" (errored)="recaptchaErrored()"></re-captcha>
        </div>
      </div>

      <button type="submit" class="btn-custom primary light btn-block" name="button"
              [disabled]="!modalForm.valid"
              (click)="onSubmitAgent()"
      >Send Message
      </button>


    </form>
    <!-- Contact End -->
  </div>

</ng-template>

<app-footer></app-footer>
