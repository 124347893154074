<div class="listing-badge featured">
  <i class="fas fa-star"></i>
</div>
<div class="acr-dots-wrapper acr-agent-thumb">
  <div class="acr-dots"></div>
  <a routerLink='/team/{{brokerage.stateSug}}'>
    <img [src]="brokerage.image" (error)="noImage($event)" alt="{{brokerage.name}}" class="adjusted">
  </a>
</div>
<div class="acr-agent-body">
  <h6><a routerLink='/team/{{brokerage.stateSug}}'>{{(brokerage.legalEntityName) ? brokerage.legalEntityName : brokerage.stateName}}</a></h6>
  <span *ngIf="(brokerage.state !== 'CA')">{{brokerage.city}}, {{brokerage.state}}</span>
  <hr/>
  <div class="acr-testimonial-author">
    <!--    <img src="assets/img/people/1.jpg" alt="testimonial">-->
    <div class="acr-testimonial-author-inner">
      <h6>{{ brokerage.brokerName ? brokerage.brokerName : '' }}</h6>
      <span>{{ brokerage.brokerName ? 'Broker - Realtor ®' : '' }}</span>
      <span><a href="tel:{{brokerage.phoneNumberE164}}">{{brokerage.phoneNumberE164 | localPhoneNumber}}</a></span>
      <span *ngIf="brokerage.state === 'CA'">DRE#02178739</span>
      <br />
    </div>
  </div>
  <a routerLink='/team/{{brokerage.stateSug}}' class="btn-custom secondary btn-xm">View Agents</a>
</div>


<!--&lt;!&ndash; Agent Start &ndash;&gt;-->
<!--<div class="listing-badge featured">-->
<!--  <i class="fas fa-star"></i>-->
<!--</div>-->
<!--<div class="acr-dots-wrapper acr-agent-thumb">-->
<!--  <div class="acr-dots"></div>-->
<!--  <a routerLink='/location/{{brokerage.stateSug}}'>-->
<!--    <img [src]="brokerage.image" (error)="noImage($event)" alt="{{brokerage.name}}">-->
<!--  </a>-->
<!--</div>-->
<!--<div class="acr-agent-body">-->
<!--  <h6><a routerLink='/location/{{brokerage.stateSug}}'>{{brokerage.stateName}}</a></h6>-->
<!--  <span>{{brokerage.city}}, {{brokerage.state}}</span>-->
<!--  <span><a href="tel:{{brokerage.phoneNumberE164}}">{{brokerage.phoneNumberE164 | localPhoneNumber}}</a></span>-->
<!--  <br/>-->
<!--  <a routerLink='/location/{{brokerage.stateSug}}' class="btn-custom secondary btn-sm">View Agents</a>-->
<!--</div>-->
<!--&lt;!&ndash; Brokerage End &ndash;&gt;-->
