import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable()
export class BrokerageService {

  protected API_URL = environment.apiUrl;


  //private brokerageWhiteList = ['FL','GA','NJ','CT','IL','PA','NC','SC','MA',
  //'MN','MI','AZ','CO','VA','NV','IN','MD','WI','TN','WA','AL','UT',
  //'MO','KS','OK','ID','NY','OR','LA', 'CA', 'KY', 'TX'];
   private brokerageWhiteList = ['WA','OR','TX','CT','FL','NJ','SC'];

  constructor(private httpClient: HttpClient) {
  }

  getBrokerage(brokerageId: number) {
    const url = `${this.API_URL}brokerage/${brokerageId}`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe((list: any) => {
        resolve(list);
      });
    });
  }

  /**
   * Get Brokerage List
   */
  getBrokerageList(activeOnly: boolean = true): any {
    const url = `${this.API_URL}brokerage`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe((list: any) => {
        let brokerages = list.filter(e => !activeOnly || this.brokerageWhiteList.includes(e.state)).map((e) => {
          e.image = `assets/img/states/${e.brokerageId}.jpg`;
          return e;
        });
        resolve(brokerages);
      });
    });
  }

  getBrokerageEmailByState(state: string): any {
    const url = `${this.API_URL}brokerage/broker-email-by-state/${state}`;
    return new Promise((resolve, reject) => {
        this.httpClient.get(url).subscribe((list: any) => {
          resolve(list);
        });
    });
  }

  getBrokerageLegalEntityName(state: string) {
    const url = `${this.API_URL}brokerage/broker-legal-entity-name/${state}`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe((list: any) => {
        resolve(list);
      });
    });
  }
}
