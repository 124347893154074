import {Component, Input, OnInit} from '@angular/core';
import {BrokerageService} from '../../../../../service/brokerage.service';
import {LocalPhoneNumberPipe} from '../../../../../common/pipes/local-phone-number.pipe';

@Component({
  selector: 'app-brokerage-single',
  templateUrl: './brokerage-single.component.html',
  styleUrls: ['./brokerage-single.component.css']
})
export class BrokerageSingleComponent implements OnInit {

  @Input() brokerage;

  constructor(protected brokerageService: BrokerageService) {
  }

  async ngOnInit() {
  }

  noImage(e) {
    e.src = 'assets/img/no-image.png';
  }

  async stateName(state) {
    const name = await this.brokerageService.getBrokerageLegalEntityName(state);
    let value: any = '';
    if (name) {
      value=name
    } else {
      value=`MVR - ${state}`;
    }
    console.log(':-)', value);
    return value
  }
}
