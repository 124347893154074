import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AgentService} from '../../../service/agent.service';
import {BrokerageService} from '../../../service/brokerage.service';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RequestService} from '../../../service/request.service';
import {GoogleAnalyticsService} from '../../../service/google-analytics.service';
import {CommonService} from '../../../service/common.service';

@Component({
  selector: 'app-brokerage-detail',
  templateUrl: './brokerage-detail.component.html',
  styleUrls: ['./brokerage-detail.component.css']
})
export class BrokerageDetailComponent implements OnInit {

  brokerageId;
  agents = [];
  brokerage;
  brokerages;
  ignoredAgentsId = [25];
  backgroundImage;
  stateName: string;
  state: string;
  emailFormatValidator = Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  selectedAgent = null;
  modalForm = null;
  brokerageForm = null;
  submissionBrokerageForm = false;

  constructor(private route: ActivatedRoute,
              private agentService: AgentService,
              private brokerageService: BrokerageService,
              private modalService: NgbModal,
              private requestService: RequestService,
              protected googleAnalyticsService: GoogleAnalyticsService,
              protected commonService: CommonService) {
  }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (params) => {
      const brokerages = await this.brokerageService.getBrokerageList();
      const brokerage = brokerages.filter(e => e.stateSug === params.slug)[0];

      this.brokerages = brokerages;
      this.brokerageId = brokerage.brokerageId;

      this.initModalForm();
      this.initBrokerageForm();

      for (const el of brokerages) {
        if (el.brokerageId === brokerage.brokerageId) {
          this.stateName = brokerage.stateName;
          this.state = brokerage.state;
        }
      }

      await Promise.all([
        this.getBrokerage(this.brokerageId),
        this.getAgentList(this.brokerageId),
      ]);
    });
  }


  /**
   * Get Brokerage Info
   */
  async getBrokerage(brokerageId) {
    this.brokerage = await this.brokerageService.getBrokerage(brokerageId);
    this.backgroundImage = await `assets/img/states/${this.brokerage.brokerageId}.jpg`;
  }

  /**
   * Get Agent By Brokerage
   */
  async getAgentList(brokerageId) {
    const agents = await this.agentService.getAgentListByBrokerage(brokerageId, true);
    if (agents) {
      this.agents = agents.map((agent) => {
        if (!agent.profileImageLargeUrl || agent.profileImageLargeUrl === '') {
          agent.profileImageLargeUrl = 'assets/img/no-image.png';
        }
        return agent;
      }).filter((agent) => agent.agentId !== 25);
    }
  }

  brokerageName(brokerage) {
    if (this.brokerage.legalEntityName) {
      return this.brokerage.legalEntityName
    } else {
      return 'MVR - '  + brokerage.state;
    }

  }

  /**
   * Brokerage Form
   */
  initBrokerageForm() {
    this.brokerageForm = this.buildFormGroup();
  }

  /**
   * Modal Form
   */
  initModalForm() {
    this.modalForm = this.buildFormGroup();
  }

  /**
   *
   */
  buildFormGroup() {
    return new FormGroup({
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, this.emailFormatValidator]),
      phone: new FormControl(null, [Validators.required]),
      address: new FormControl(null),
      city: new FormControl(null),
      state: new FormControl(null),
      zip: new FormControl(null),
      message: new FormControl(null, [Validators.required]),
      terms: new FormControl(false, [Validators.requiredTrue]),
      recaptcha: new FormControl(null, [Validators.required]),
    });
  }

  async openContactAgentModal(content, agent) {
    this.selectedAgent = await agent;
    this.modalService.open(content, {centered: true});
  }

  onSubmitBrokerage() {
    const formData = this.brokerageForm.getRawValue();
    const data = this.brokerageService.getBrokerageEmailByState(this.state);
    const brokerEmail = data.body;

    formData.subject = `Homesatmv ${this.stateName} Brokerage Form`;
    formData.recipients = ['david@homesatmv.com', 'amanda@homesatmv.com', 'sunny@homesatmv.com', 'stevenk@homesatmv.com'];
    if (!formData.recipients.includes(brokerEmail)) {
      formData.recipients = formData.recipients.concat([brokerEmail]);
    }
    this.requestService.sendEmail(formData).then((response: any) => {
      if (response.success) {
        this.submissionBrokerageForm = true;
        this.googleAnalyticsService.emitEvent('form', 'submit', 'contact', 1);
      } else {
        alert('Not possible to complete your request, try in a few minutes');
      }
      this.brokerageForm.reset();
    });
  }

  onSubmitAgent() {
    const formData = this.modalForm.getRawValue();
    formData.recipients = ['david@homesatmv.com', 'amanda@homesatmv.com', 'sunny@homesatmv.com', 'stevenk@homesatmv.com'];

    if (this.selectedAgent && this.selectedAgent.emailAddress) {
      formData.recipients.push(this.selectedAgent.emailAddress);
      formData.subject = `Homesatmv Agent Contact Form (${this.selectedAgent.fullName})`;
      formData.submitToAgent = true;

      // Send to CRM
      const crmDataFormatted = this.commonService.mapRequestMVValues(formData, this.selectedAgent.emailAddress);
      // this.requestService.sendLead(crmDataFormatted).then(() => {
      this.requestService.sendEmail(formData).then((response: any) => {
        if (response.success) {
          this.googleAnalyticsService.emitEvent('form', 'submit', 'contact', 1);
          this.modalService.dismissAll(`Success`);
        } else {
          alert('Not possible to complete your request, try in a few minutes');
        }
        this.brokerageForm.reset();
      });
      // });
    } else {
      alert('Not possible to complete your request, try in a few minutes');
    }
  }

  recaptchaErrored()  {
    console.warn('reCAPTCHA error encountered.');
  }

  recaptchaResolved(response: string) {
    console.log('Resolved captcha with response: "', response, '"');
  }

}
