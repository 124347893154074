<app-header-four></app-header-four>
<!-- Subheader Start -->
<div class="header-1"></div>
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/banner/main.jpg')">
  <div class="container">
    <div class="subheader-inner">
      <h1 class="text-white">About</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink='/'> <i class="fas fa-home"></i> </a></li>
          <li class="breadcrumb-item active" aria-current="page">About Us</li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<!-- About Section Start -->
<div class="section light-bg">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="section-title-wrap mr-lg-30">
          <h5 class="custom-primary">About Us</h5>
          <h2 class="title">MV Realty</h2>
          <p class="subtitle">
            <a href="/">MV Realty</a> is a real estate brokerage created by seasoned, technologically savvy, real-estate professionals to meet the needs of the modern broker, buyer, and seller. Our company provides an efficient, smart way to conduct real estate transactions, to ensure satisfaction to our consumer. We leverage data from more than just the MLS – we tap into detailed financial and property data, and utilize the latest mapping and search tools to help connect people with the properties they truly want. At <a href="http://mvrealtyfl.com">MV Realty</a>, we believe it's time for the real estate community to simply work smarter.
          </p>
          <br>
          <p class="subtitle">Looking for Something Different? We Were Too. MV Realty is changing the world of real estate with the Homeowner Benefit Program®. We’re building lifelong relationships with clients through our innovative loyalty program, and offering support and guidance every step of the way.</p>
        </div>
      </div>
      <div class="col-lg-6 mb-lg-30 acr-dots-wrapper acr-single-img-wrapper">
        <img src="assets/img/states/1.jpg" alt="MV Realty FL">
        <div class="acr-dots"></div>
      </div>
    </div>
  </div>
</div>

<!-- About Section End -->

<!-- Subheader End -->
<div class="section">
  <div style="text-align: center;">
    <h2>Executive Team</h2>
  </div>
  <div class="container">
    <div class="acr-single-agent">
      <div class="acr-single-agent-thumb">
        <img src="assets/img/amanda_about.png" alt="Amanda Zachman" class="img-thumbnail" style="vertical-align: middle;">
      </div>
      <div class="acr-single-agent-body">
        <h5>Founder/CSO - Amanda Zachman</h5>
        <p>Amanda Zachman is an entrepreneur, business leader, and mom of 3 with more than 12 years of experience in the real estate industry. In 2014, Zachman founded MV Realty, a disruptive tech-focused real estate brokerage and proptech firm dedicated to meeting the needs of the modern broker, home buyer, and home seller. She is also part of the team that developed the company’s innovative Homeowner Benefit Program®, which pays homeowners a cash payment in exchange for agreeing to use MV Realty as its real estate agency, should they ever choose to sell their home. In 2017, Zachman arranged the signing of the first Homeowner Benefit agreement in Florida.</p>
        <p>Under Amanda's leadership, the rapid evolution of MV Realty has generated significant traction in a brief period. The company's Homeowner Benefit Program has grown from 7,778 contracts in 2021 to 32,000 as of August 2022. MV now averages 3,480 new HBP contracts a month and is on track to expand its portfolio to over 100,000 over the next 12 months. It is increasing the minimum future listing commission value from $55 million to $288 million. It is projected to reach +425 million worth by December 2022. MV Realty has grown from 75 to 500+ agents in 33 states and is expected to have 800+ agents by December 2022.</p>
        <p>Zachman manages the company’s real estate and legal departments, overseeing all real estate transactions, supervising principal brokers, and spearheading the expansion of the Homeowner Benefit Program across 33 states. She also devotes much of her time to raising awareness of the company’s mission and offerings on a local, regional, and national level.</p>
        <p>Previously, Zachman was a realtor with Berkshire Hathaway Florida Realty and Coldwell Realty. Earlier in her career, she worked in TV production in various roles.
          Zachman is an active National Association of Realtors member and a licensed broker in Florida, Georgia, Illinois, Massachusetts, Pennsylvania, and Connecticut.
          She studied musical theater at The American Musical and Dance Academy in New York. Subsequently, she moved to Florida: Today, she resides in Boca Raton with her husband Michael and their two children, with one on the way, Madison and Ford. She enjoys Pilates, watching movies, and traveling in her spare time.</p>
      </div>
    </div>
  </div>
  <br/>
  <div class="container">
    <div class="acr-single-agent">
      <div class="acr-single-agent-thumb">
        <img src="assets/img/tony_about.png" alt="Tony Mitchell" class="img-thumbnail" style="vertical-align: middle;">
      </div>
      <div class="acr-single-agent-body">
        <h5>CEO - Tony Mitchell</h5>
        <p>Tony (Antony) Mitchell is a specialty financial services and turnaround specialist with more than 30 years’ experience in developing and growing organizations focused on alternative financial asset classes. During his career, he helped pioneer, develop, institutionalize and raise money for new asset classes for companies marketing structured settlements for lottery and litigation award recipients, annuities and life settlements.</p>
        <p>He joined MV Realty in 2018 in order to help the company expand market penetration of its novel Homeowner Benefit Program® which provides homeowners with a cash payment in exchange for agreeing to use MV Realty as its real estate agency, should they ever choose to sell their home. Mitchell views the company as a proptech real estate organization with plans to leverage its technology platform to attract agents and consumers and grow nationwide.</p>
        <p>Previously, Mitchell was brought in by investors in 2017 to help grow Los Angeles-based Film Finance (FFI), the world leader in film industry completion contracts. FFI grew from $12 million in revenues to going public with a $300 million valuation. The company suffered setbacks when The Weinstein Company, which was a significant contributor to the company’s revenues, shut down due to its well documented scandals. Mitchell then took Film Finance private and helped it survive the entertainment industry shutdown due to COVID-19. The company has since rebounded in 2021 and 2022 with record revenues; Mitchell remains on its board.</p>
        <p>For more than 10 years through 2017, Mitchell was CEO and a director of Emergent Capital (formerly Imperial Holdings), a company that invested in life settlements. From October 2013 through December 2015, he served as CEO and a board member of DRB Capital, a structured settlement and annuity company and Blackstone affiliate.</p>
        <p>Mitchell, from 2001 through 2007, was chief operating officer and executive director of Peach Holdings, a holding company which through its subsidiaries, was a provider of specialty factoring services. Peach Holdings completed its initial public offering in March 2006 and was subsequently acquired by an affiliate of Credit Suisse in November 2006.</p>
        <p>He was also a co-founder of Singer Asset Finance Company (a subsidiary of Enhance Financial Services Group) which was involved in acquiring insurance policies, structured settlements and other types of receivables.</p>
        <p>His past board memberships include director, and later executive chair, of Ram Power, a renewable energy company listed on the Toronto Stock Exchange; and a past chair of the board of Polaris Geothermal, a company focused on the generation of renewable energy projects.</p>
      </div>
    </div>
  </div>

</div>

<app-footer></app-footer>
